body {
    /*padding: 10px;*/
    background-color: #222;
    color: #ddd;
}

.cls-select {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 3px 10px;
    color: #aaa;
}

.cls-select:hover {
    background-color: #333;
}

.cls-select.active {
    /*border: 1px solid #ddd;*/
    background-color: #6c7589;
    /*border-color: #4d7ff2;*/
    /*color: #4d7ff2;*/
    color: #eee;
}

.trade-item-icon {
    width: 40px;
    height: 40px;
    background-color: #000;
    border-width: 2px;
    border-style: solid;
    border-radius: 3px;
    margin-right: 5px;
}

.item-name {
    font-weight: bold;
}

.item-quality .trade-item-icon, .item-quality .item-name {
    color: #000
}

.item-quality-0 .trade-item-icon, .item-quality-0 .item-name {
    color: #c8c8c8
}

.item-quality-1 .trade-item-icon, .item-quality-1 .item-name {
    color: #3c3
}

.item-quality-2 .trade-item-icon, .item-quality-2 .item-name {
    color: #3ca5ff
}

.item-quality-3 .trade-item-icon, .item-quality-3 .item-name {
    color: #DC4CFF
}

.item-quality-4 .trade-item-icon, .item-quality-4 .item-name {
    color: #ffdb46
}

.item-entry {
    display: flex;
    align-items: center;
}

.item-entry .info {
    display: block;
}

.entry {
    margin: 10px;
}

.filter {
    display: inline-block;
    background-color: #444;
    padding: 2px;
    margin: 5px;
    border-radius: 5px;
    font-size: 13px;
}

.tips .suggested-price {
    font-size: 22px;
    font-weight: bold;
}

.tips b {
    color: #666;
}

.raise {
    color: red;
}

.fall {
    color: green;
}

.tips td {
    padding: 1px 3px;
}

.price {
    font-size: 16px;
    font-weight: bold;
}

.main-body {
    max-width: 1400px;
    margin: 10px auto;
    background-color: #333;
    border-radius: 10px;
    border: 1px solid #555;
    padding: 10px;
}

